.chitchat-loader{
    background-color: $white;
    margin: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    text-align: center;
    img{
        height: 150px;
    }
    h3{
        margin-top: 30px;
        color: $gray;
    }
}
@keyframes imggrow {
    0% {transform: scale(1);}
    50% {transform: scale(1.2);}
    100% {transform: scale(1);}
}
.type{
    width: 60px;
    position: relative;
    height: 15px;
}
.typing-loader{
    width: 8px;
    height: 8px;
    border-radius: 100%;
    animation: loadertyping 0.8s ease-in-out alternate infinite;
    animation-delay: 0.32s;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -27px;
    &:before,&:after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        box-shadow: 0 40px 0 $white;
        animation: loadertyping 0.8s ease-in-out alternate infinite;
    }
    &:before{
        left: -17px;
        animation-delay: 0.48s;
    }
    &:after{
        right: -17px;
        animation-delay: 0.16s;
    }
}
@keyframes loadertyping {
    0% {
        box-shadow: 0 40px 0 $white; }
    100% {
        box-shadow: 0 20px 0 $white; } }