.custom-scroll{
  scroll-behavior: smooth;
  &::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px $light-color;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba($primary-color, 0.15);
  }
}
.scroll-invisiable{
  &::-webkit-scrollbar {
    width: 0;
  }
}
.owl-carousel{
  position: unset;
}