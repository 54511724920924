
//colors
$black: #000000;
$white: #ffffff;
$primary-color: #1c9dea;
$primary-light: #eff7fe;
$success-color: #3fcc35;
$danger-color: #ff4e2b;
$warning-color: #ffc514;
$light-color: #eff1f2;
$dark-color: #223645;
$gray:  #828282;
$gray1: #e5edf5;
$gray2: #f4f5f6 ;

$facebook: 	#3b5998;
$instagram: #e4405f;
$twiter: #55acee;
$google: #dc4e41;
$linkedin: #0e76a8;

$slack: #3aaf85;
$dark : #191b1f;
$light : #25262c;
$dark-border : #414248;
$border-color2: #ddd;
$cross-color:#d0eafb;


// Font family
$font-1: 'Montserrat', sans-serif;
$font-2: 'Roboto', sans-serif;
$font-3: 'Rubik', sans-serif;
$icon-stroke-width : 3px;
$border-color: #eff1f2;
$border-color1: #e5e8e9;
$muted-font: #647589;
$radious100 : 100%;
$btn-radious: 10px;
$radious25 : 25px;
$radious5 : 5px;
$radious15 : 15px;
$radious20 : 20px;

//Grid setting 
$main-nav-width: 100px;
$left-sidebar-width: 400px;
$right-sidebar-width: 350px;

// sibar seting
$sidebar-spacing: 40px;
$contant-spacing: 45px;


// landing page
$header-back:#eff8ff;
$red: #ff3030 ;
$light-blue:#1c77ed ;
$light-blue1:#1da8fd;
$black1:#595959;
$test-back:#e9f5ff ;
$transitionDefault: 0.25s ease-out 0.10s;
$value : 5px;
