@-webkit-keyframes bounce {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-30px);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-15px);
	}
}
@keyframes bounce {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-30px);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-15px);
	}
}
@-webkit-keyframes flash {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
	25% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
}
@keyframes flash {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
	25% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
}
@-webkit-keyframes jello {
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	0.1% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	1.2% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-12.5deg) skewY(-12.5deg);
	}
	2.3% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(6.25deg) skewY(6.25deg);
	}
	3.4% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}
	4.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(1.5625deg) skewY(1.5625deg);
	}
	5.6% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-0.78125deg) skewY(-0.78125deg);
	}
	6.7% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(0.39063deg) skewY(0.39063deg);
	}
	7.8% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-0.19531deg) skewY(-0.19531deg);
	}
}
@keyframes jello {
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	0.1% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	1.2% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-12.5deg) skewY(-12.5deg);
	}
	2.3% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(6.25deg) skewY(6.25deg);
	}
	3.4% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}
	4.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(1.5625deg) skewY(1.5625deg);
	}
	5.6% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-0.78125deg) skewY(-0.78125deg);
	}
	6.7% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(0.39063deg) skewY(0.39063deg);
	}
	7.8% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-0.19531deg) skewY(-0.19531deg);
	}
}
@-webkit-keyframes pulse {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
}
@keyframes pulse {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
}
@-webkit-keyframes rubberBand {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
}
@keyframes rubberBand {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
}
@-webkit-keyframes shake {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	10% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
}
@keyframes shake {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	10% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
}
@-webkit-keyframes swing {
	20% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(15deg);
	}
	40% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-10deg);
	}
	60% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(5deg);
	}
	80% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-5deg);
	}
	100% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0deg);
	}
}
@keyframes swing {
	20% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(15deg);
	}
	40% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-10deg);
	}
	60% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(5deg);
	}
	80% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-5deg);
	}
	100% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0deg);
	}
}
@-webkit-keyframes tada {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
	10% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.9) rotate(-3deg);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.9) rotate(-3deg);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(3deg);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(3deg);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(3deg);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(3deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(-3deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(-3deg);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(-3deg);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1) rotate(0);
	}
}
@keyframes tada {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
	10% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.9) rotate(-3deg);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.9) rotate(-3deg);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(3deg);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(3deg);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(3deg);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(3deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(-3deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(-3deg);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1) rotate(-3deg);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1) rotate(0);
	}
}
@-webkit-keyframes wobble {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%);
	}
	15% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-25%) rotate(-5deg);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(20%) rotate(3deg);
	}
	45% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-15%) rotate(-3deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10%) rotate(2deg);
	}
	75% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-5%) rotate(-1deg);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%);
	}
}
@keyframes wobble {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%);
	}
	15% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-25%) rotate(-5deg);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(20%) rotate(3deg);
	}
	45% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-15%) rotate(-3deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10%) rotate(2deg);
	}
	75% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-5%) rotate(-1deg);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%);
	}
}
@-webkit-keyframes bounceIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.3);
	}
	50% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.05);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.9);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
}
@keyframes bounceIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.3);
	}
	50% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.05);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.9);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
}
@-webkit-keyframes bounceInDown {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(30px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-10px);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@keyframes bounceInDown {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(30px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-10px);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@-webkit-keyframes bounceInLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(30px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@keyframes bounceInLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(30px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-10px);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@-webkit-keyframes bounceInRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-30px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@keyframes bounceInRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-30px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(10px);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@-webkit-keyframes bounceInUp {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-30px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(10px);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@keyframes bounceInUp {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-30px);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(10px);
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@-webkit-keyframes bounceOut {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
	25% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.95);
	}
	50% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.3);
	}
}
@keyframes bounceOut {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1);
	}
	25% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.95);
	}
	50% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(1.1);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.3);
	}
}
@-webkit-keyframes bounceOutDown {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-20px);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
}
@keyframes bounceOutDown {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-20px);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
}
@-webkit-keyframes bounceOutLeft {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(20px);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
}
@keyframes bounceOutLeft {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(20px);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
}
@-webkit-keyframes bounceOutRight {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-20px);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
}
@keyframes bounceOutRight {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-20px);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
}
@-webkit-keyframes bounceOutUp {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(20px);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
}
@keyframes bounceOutUp {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(20px);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
}
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@keyframes fadeInDown {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@-webkit-keyframes fadeInDownBig {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@keyframes fadeInDownBig {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@keyframes fadeInLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@-webkit-keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(20px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@keyframes fadeInRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(20px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@-webkit-keyframes fadeInRightBig {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@keyframes fadeInRightBig {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@keyframes fadeInUp {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@-webkit-keyframes fadeInUpBig {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@keyframes fadeInUpBig {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes fadeOutDown {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(20px);
	}
}
@keyframes fadeOutDown {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(20px);
	}
}
@-webkit-keyframes fadeOutDownBig {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
}
@keyframes fadeOutDownBig {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
}
@-webkit-keyframes fadeOutLeft {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-20px);
	}
}
@keyframes fadeOutLeft {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-20px);
	}
}
@-webkit-keyframes fadeOutLeftBig {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
}
@keyframes fadeOutLeftBig {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
}
@-webkit-keyframes fadeOutRight {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(20px);
	}
}
@keyframes fadeOutRight {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(20px);
	}
}
@-webkit-keyframes fadeOutRightBig {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
}
@keyframes fadeOutRightBig {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
}
@-webkit-keyframes fadeOutUp {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-20px);
	}
}
@keyframes fadeOutUp {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-20px);
	}
}
@-webkit-keyframes fadeOutUpBig {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
}
@keyframes fadeOutUpBig {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
}
@-webkit-keyframes flip {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(0);
		animation-timing-function: ease-out;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) translateZ(150px) rotateY(170deg);
		animation-timing-function: ease-out;
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		animation-timing-function: ease-in;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(360deg) scale(0.95);
		animation-timing-function: ease-in;
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) scale(1);
		animation-timing-function: ease-in;
	}
}
@keyframes flip {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(0);
		animation-timing-function: ease-out;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) translateZ(150px) rotateY(170deg);
		animation-timing-function: ease-out;
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		animation-timing-function: ease-in;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(360deg) scale(0.95);
		animation-timing-function: ease-in;
	}
	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) scale(1);
		animation-timing-function: ease-in;
	}
}
@-webkit-keyframes flipInX {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(90deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(-10deg);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(10deg);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(0deg);
	}
}
@keyframes flipInX {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(90deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(-10deg);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(10deg);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(0deg);
	}
}
@-webkit-keyframes flipInY {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(90deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(-10deg);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(10deg);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(0deg);
	}
}
@keyframes flipInY {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(90deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(-10deg);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(10deg);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(0deg);
	}
}
@-webkit-keyframes flipOutX {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(0deg);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(90deg);
	}
}
@keyframes flipOutX {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(0deg);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateX(90deg);
	}
}
@-webkit-keyframes flipOutY {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(0deg);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(90deg);
	}
}
@keyframes flipOutY {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(0deg);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotateY(90deg);
	}
}
@-webkit-keyframes lightSpeedIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(100%) skewX(-30deg);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-20%) skewX(30deg);
	}
	80% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%) skewX(-15deg);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%) skewX(0deg);
	}
}
@keyframes lightSpeedIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(100%) skewX(-30deg);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-20%) skewX(30deg);
	}
	80% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%) skewX(-15deg);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%) skewX(0deg);
	}
}
@-webkit-keyframes lightSpeedOut {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%) skewX(0deg);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(100%) skewX(-30deg);
	}
}
@keyframes lightSpeedOut {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0%) skewX(0deg);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(100%) skewX(-30deg);
	}
}
@-webkit-keyframes rotateIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-200deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center center;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center center;
	}
}
@keyframes rotateIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-200deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center center;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center center;
	}
}
@-webkit-keyframes rotateInDownLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
}
@keyframes rotateInDownLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
}
@-webkit-keyframes rotateInDownRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
}
@keyframes rotateInDownRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
}
@-webkit-keyframes rotateInUpLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
}
@keyframes rotateInUpLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
}
@-webkit-keyframes rotateInUpRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
}
@keyframes rotateInUpRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
}
@-webkit-keyframes rotateOut {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center center;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(200deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center center;
	}
}
@keyframes rotateOut {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center center;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(200deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center center;
	}
}
@-webkit-keyframes rotateOutDownLeft {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
}
@keyframes rotateOutDownLeft {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
}
@-webkit-keyframes rotateOutDownRight {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
}
@keyframes rotateOutDownRight {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
}
@-webkit-keyframes rotateOutUpLeft {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
}
@keyframes rotateOutUpLeft {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
	}
}
@-webkit-keyframes rotateOutUpRight {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
}
@keyframes rotateOutUpRight {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
	}
}
@-webkit-keyframes slideInDown {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@keyframes slideInDown {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@-webkit-keyframes slideInLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@keyframes slideInLeft {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@-webkit-keyframes slideInRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@keyframes slideInRight {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
}
@-webkit-keyframes slideInUp {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@keyframes slideInUp {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
}
@-webkit-keyframes slideOutDown {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
}
@keyframes slideOutDown {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(2000px);
	}
}
@-webkit-keyframes slideOutLeft {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
}
@keyframes slideOutLeft {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-2000px);
	}
}
@-webkit-keyframes slideOutRight {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
}
@keyframes slideOutRight {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2000px);
	}
}
@-webkit-keyframes slideOutUp {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
}
@keyframes slideOutUp {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-2000px);
	}
}
@-webkit-keyframes hinge {
	0% {
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	20% {
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(80deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	60% {
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(80deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	40% {
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(60deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	80% {
		opacity: 1;
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(60deg) translateY(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(700px);
	}
}
@keyframes hinge {
	0% {
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	20% {
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(80deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	60% {
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(80deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	40% {
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(60deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	80% {
		opacity: 1;
		animation-timing-function: ease-in-out;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(60deg) translateY(0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(700px);
	}
}
@-webkit-keyframes rollIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-100%) rotate(-120deg);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0px) rotate(0deg);
	}
}
@keyframes rollIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-100%) rotate(-120deg);
	}
	100% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0px) rotate(0deg);
	}
}
@-webkit-keyframes rollOut {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0px) rotate(0deg);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(100%) rotate(120deg);
	}
}
@keyframes rollOut {
	0% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0px) rotate(0deg);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(100%) rotate(120deg);
	}
}
@-webkit-keyframes zoomIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}
@keyframes zoomIn {
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}
@-webkit-keyframes zoomInDown {
	0% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
	}
}
@keyframes zoomInDown {
	0% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
	}
}
@-webkit-keyframes zoomInLeft {
	0% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
	}
}
@keyframes zoomInLeft {
	0% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
	}
}
@-webkit-keyframes zoomInRight {
	0% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
	}
}
@keyframes zoomInRight {
	0% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
	}
}
@-webkit-keyframes zoomInUp {
	0% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
	}
}
@keyframes zoomInUp {
	0% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
	}
}
@-webkit-keyframes zoomOut {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.3, 0.3, 0.3);
	}
	100% {
		opacity: 0;
	}
}
@keyframes zoomOut {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.3, 0.3, 0.3);
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes zoomOutDown {
	40% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
	}
	100% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center bottom;
	}
}
@keyframes zoomOutDown {
	40% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
	}
	100% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center bottom;
	}
}
@-webkit-keyframes zoomOutLeft {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.1) translate3d(-2000px, 0, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left center;
	}
}
@keyframes zoomOutLeft {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.1) translate3d(-2000px, 0, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left center;
	}
}
@-webkit-keyframes zoomOutRight {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.1) translate3d(2000px, 0, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right center;
	}
}
@keyframes zoomOutRight {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
	}
	100% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(0.1) translate3d(2000px, 0, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right center;
	}
}
@-webkit-keyframes zoomOutUp {
	40% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
	}
	100% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center bottom;
	}
}
@keyframes zoomOutUp {
	40% {
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
	}
	100% {
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center bottom;
	}
}