.chitchat-container{
	&.sidebar-overlap{		
		.chitchat-main,.message-input,.contact-details{
			width: calc(100% - 500px)!important;
		}
	}
	.main-nav{
		.logo-warpper{
			text-align: center;
			padding-bottom: 35px;
			border-bottom: 1px solid $border-color;
			img{
				height: $sidebar-spacing;
			}
		}
		.sidebar-main{
			height: calc(100vh - 186px);
			display: inline-flex;
			flex-direction: column;
			width: 100%;
			justify-content: space-between;
			.sidebar-top,.sidebar-bottom{
				.btn-light{
					&.active{
						background-color: $primary-color !important;
						i{
							color: $white;
						}
					}
				}
				li{
					text-align: center;
					padding-top: 30px;
				}
			}
		}
	}

	
	.chitchat-left-sidebar{
		.fevorite-tab,.document-tab,.contact-list-tab,.notification-tab,.settings-tab,.recent-default,.status-tab{
			position: absolute;
			min-height: calc(100vh);
			overflow: auto;
			overflow-x: hidden;
			overflow-y: auto;
			top: 0;
			bottom: 0;
			background-color: $white;
			z-index: 0;
			width: 400px;
			min-width: 400px;
			padding: $sidebar-spacing;
			&.active{
				opacity: 1;
				z-index: 3;
				animation: fadeInLeft 300ms ease-in-out;
			}
			.my-status-box {
				border-bottom: 1px solid $border-color;
				padding-bottom: 20px;
				.status-content {
					.media {
						.img-status {
							background-color: gray;
							width: fit-content;
							border-radius: 50%;
							margin-right: 15px;
							position: relative;
							.bg-size{
								width: 50px;
								height: 50px;
								border-radius: 50%;
							}
							img {
								width: 50px;
								height: 50px;
								border-radius: 50%;
							}
							.upload-img {
								border-radius: 50%;
								width: 20px;
								height: 20px;
								background-color: $primary-color;
								position: absolute;
								bottom: -8px;
								right: 0;
								color: $white;
								&:before {
									content: "\f067";
									font-family: FontAwesome;
									font-size: 11px;
									padding: 6px;
								}
								input{
									background-color: gray;
									border-radius: 50%;
									width: 100%;
									height: 100%;
									opacity: 0;
									position: absolute;
									top: 0;
								}
							}
						}
						.media-body {
							h3 {
								text-transform: capitalize;
								margin-bottom: 5px;
							}
							p {
								margin-bottom: 0;
								text-transform: capitalize;
								letter-spacing: 1px;
							}
						}
					}
				}
			}
			.contact-status-box {
				padding-top: 20px;
				h3 {
					padding: 15px 25px;
					color: $primary-color;
					background-color: $primary-light;
					text-transform: capitalize;
					margin-bottom: 20px;
					border-radius: 15px;
				}
				.status-content {
					margin-bottom: 20px;
					border-bottom: 1px solid $border-color;
					padding-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
					ul {
						display: flex;
						align-items: center;
						li {
							h5 {
								margin-bottom: 5px;
								text-transform: capitalize;
							}
							.img-status {
								width: 40px;
								height: 40px;
								border-radius: 50%;
								position: relative;
							}
							p {
								margin-bottom: 0;
								text-transform: capitalize;
							}
							&:first-child {
								margin-right:15px;
							}
						}
					}
				}
			}
		}
		.recent-default{
			position: relative;
			z-index: 0;
			padding: 0 !important;
			&.active{
				z-index: 999;
			}
			.recent{
				position: fixed;
				width: 400px;
				top: 0;
				background-color: $white;
				padding-top: $sidebar-spacing;
				z-index: 1;
				.theme-title{
					padding: 0 $sidebar-spacing;
				}
				.recent-slider{
					padding: 20px $sidebar-spacing 0 $sidebar-spacing;
					.recent-box{
						&:hover{
							.recent-profile{
								box-shadow: -1px 10px 16px -10px rgba(102,102,102,1);
								transition: all 0.3s ease;
							}
						}
						.dot-btn{
							position: absolute;
							bottom: 25px;
							right: 15px;
						}
						.recent-profile{
							height: 110px;
							width: 100%;
							border-radius: $radious20;
							position: relative;
							transition: all 0.3s ease;
							h6{
								position: absolute;
								color: $white;
								bottom: 10px;
								left: 15px;
								font-weight: 500;
								text-transform: capitalize;
							}
							&:before{
								content: "";
								position: absolute;
								height: 100%;
								width: 100%;
								border-radius: 15px;
								box-shadow: inset 0px -25px 40px 6px rgba(0, 0, 0, 0.8);
							}
						}
					}
				}
				&~.chat{
					margin-top: 242px;
					height: calc(100vh - 242px);
					min-height: calc(100vh - 242px);
				}
			}
			.chat{
				overflow: auto;
				overflow-x: hidden;
				overflow-y: auto;
				>.theme-tab{
					padding: $sidebar-spacing;
					&.tab-sm{
						> .nav-tabs{
							padding-bottom: 30px;
						}
						&.tab-radious{
							.nav-tabs{
								width: 80%;
								margin: 0 auto;
								> .nav-item .nav-link{
									border-radius: $radious5;
								}
							}
						}
					}
				}
				.theme-title{
					padding: $sidebar-spacing $sidebar-spacing 0 $sidebar-spacing;
				}
			}
		}
	}
}

.chitchat-left-sidebar{
	[class*="-tab"]{
		.search-form .form-group .icon-close{
			top: 10px;
		}
	}
}

.chitchat-left-sidebar{
	.search-form .form-group .icon-close{
		top: 0px;
	}
}
.search-form{
	width: calc(100% - 60px);
	left: 30px;
	height: calc(100% - 30px);
	position: absolute;
	top: 30px;
	&:before{
		position: absolute;
		left: 25px;
		content: "\e610";
		color: $dark-color;
		font-family: 'themify';
		font-weight: 600;
		font-size: 14px;
	}
	.form-group{
		height: 100%;
		width: 100%;
		margin-top: 0;
		input{
			border: 1px solid $border-color;
			padding: 0 50px;
			height: 100%;
			width: 100%;
			border-radius: 15px;
			font-size: 16px;
			&:focus{
				outline: none;
			}
		}
		.icon-close{
			position: absolute;
			padding: 23px;			
			right: 0;
			cursor: pointer;
		}
	}
}
.search{
	&~.search-form{
		transform: scale(0);
		transition: all 0.3s ease;
		&.open{
			transform: scale(1);
			transition: all 0.3s ease;
		}
	}
}
.contact-list-tab{
	li .chat-box .date-status {
		top: 10px;
	}
	.chat-main{
		margin-right: -40px;
		padding-right: 40px;
		height: 100vh;		
	}
}
.fevorite-tab,.document-tab,.contact-list-tab{
	.search-form{
		top: -15px;
		left: 0px;
		position: absolute;
		height:75px;
		width  :100%;
		&:before{
			position: absolute;
			left: 25px;
			content: "\e610";
			color: $dark-color;
			font-family: 'themify';
			font-weight: 600;
			font-size: 14px;
		}
	}
}
.chitchat-right-sidebar{
	transform-style: preserve-3d;
	transform-origin: center right;
	transition: transform 0.5s;
	cursor: pointer;
	.status{
		.str-msg{
			li{
				&+li{
					margin-top: 15px;
				}
			}
		}
	}
	.right-sidebar{
		overflow: auto;
		overflow-x: hidden;
		overflow-y: auto;
		height: 100vh;
		max-height: 100vh;
		position: absolute;
		background-color: $white;
		width: 100%;
		backface-visibility: hidden;
		.group-main{
			margin-bottom: -20px;
			li{
				padding-top: 0;
				&+li{
					margin-top: 0;
				}
			}
			.group-box {
				.profile{
					width: 30px;
					height: 30px;
				}
				.details{
					padding-left: 45px;
					padding-top: 0;
				}
			}
		}
	}
	.contact-profile,.document,.media-gallery,.status {
		opacity: 0.5;
		transition: all 0.7s;
	}
	.contact-profile{
		padding: $sidebar-spacing;
		.details{
			padding-top: $sidebar-spacing;
			text-align: center;
			.contact-top{
				height: 150px;
				width: 140px;
				border-radius: $radious20;
				box-shadow: -1px 10px 16px -10px rgba(102,102,102,1);
				margin: 0 auto;
			}
			.name{
				margin-top: 20px;
				h6{
					padding-top: 8px;
					margin-bottom: 20px;
				}
			}
			ul{
				li{
					display: inline-block;
					svg{
						fill: $white;
					}
					&+li{
						margin-left: 12px;
					}
				}
			}
		}
	}
	.document,.media-gallery,.status{
		border-top: 1px solid $border-color;
		padding: 30px $sidebar-spacing ;
	}
	.status{
		position: relative;
		.bg-size{
			margin: 3px;
		}
		&.other{
			ul{
				li{
					padding-left: 30px;
				}
			}
			h5{
				a{
					color: rgba(34, 54, 69, 0.8);
					font-weight: 600;
					display: flex;
					svg{
						height: 14px;
						position: absolute;
						left: 0;
					}
				}
			}
		}
		ul{
			li{
				position: relative;
				padding-left: 50px;
				.switchery{
					position: absolute;
					left: 0;
				}
				&+li{
					margin-top: 20px;
				}
			}
		}
	}
}
.document-list{
	li{
		position: relative;
		i{
			position:absolute;
			font-size: 16px;
			top: -2px;
			font-weight: 700;
		}
		h5{
			margin-left:30px;
			color:$muted-font;
		}
		&+li{
			margin-top: 15px;
		}
	}
}
.share-media{
	margin-left: -8px;
	margin-right: -8px;
	.media-big{
		height:160px;
		width:auto;
		.border-portfolio {
			a {
				height: 160px;
				width: auto;
			}
		}
		.isotopeSelector  {
			img {
				height: 100%;
				width: auto;
			}
		}
	}
	.col-4{
		padding-left: 8px;
		padding-right: 8px;
	}
	.media-small{
		height:72px;
		width:auto;
		border-radius:15px;
		.border-portfolio {
			a {
				height: 72px;
				width: auto;
			}
		}
		&:first-child {
			margin-bottom:16px;
		}
	}
}

// swipe menu
.menu-trigger{
	cursor: pointer;
}
.menu-active {
	.chitchat-container .chitchat-main{
		width: calc(100% - 850px);
		transition: all 0.7s;
	}
	.chitchat-right-sidebar {
		opacity: 1;
		right: 0;
		z-index: 9;
		transition: all 0.7s;
		.contact-profile,.document,.media-gallery,.status {
			opacity: 1;
			transition: all 0.7s;
		}
	}
}
.menu-trigger{
	cursor: pointer;
}
.sidebar-active {
	.sidebar-toggle{
		.main-nav.on{
			~ .chitchat-main .message-input{
				width: calc(100% - 890px);
			}
		}
	}
	.chitchat-container .chitchat-main{
		width: calc(100% - 890px);
		transition: all 0.7s;
		&.small-sidebar{
			width: calc(100% - 590px);
			transition: all 0.7s;
			.message-input {
				width: calc(100% - 500px);
			}
			.messages{
				.contact-details{
					width: calc(100vw - 575px);
				}
			}
		}
	}
	.sidebar-toggle .main-nav.on ~ .chitchat-main .contact-details {
		width: calc(100vw - 980px);
		transition: all 0.3s ease;
	}
	.sidebar-toggle .main-nav.on ~ .chitchat-main.small-sidebar{
		.contact-details{
			width: calc(100vw - 680px);
			transition: all 0.3s ease;
		}
	}
	.sidebar-toggle{
		.main-nav{
			&.on{
				~ .chitchat-main{
					&.small-sidebar{
						.message-input{
							width: calc(100% - 590px);
						}
					}
				}
			}
		}
	}
}
.menu-active {
	.chitchat-container .chitchat-main{
		width: calc(100% - 850px);
		transition: all 0.7s;
	}
	.chitchat-main{
		.message-input {
			width: calc(100% - 850px);
			transition: all 0.9s;
		}
		.messages {
			.contact-details {
				width: 50vw !important;
				transition: all 0.9s ease;
			}
		}
	}
}
.sidebar-active{
	.sidebar-toggle{
		.main-nav{
			&:not(.on){
				&~.chitchat-main{
					&:not(.small-sidebar){
						width: calc(100% - 790px);
						.contact-details{
							width: calc(100vw - 880px);
						}
						.message-input{
							width: calc(100% - 790px);
						}
					}
				}
			}
		}
	}
}
.sidebar-toggle{
	.main-nav{
		transition: all 0.3s ease;
		transform: translateX(-100px);
		animation: fadeInLeft 300ms ease-in-out;
		min-width: 0;
		width: 0;
		&:not(.on){
			&~.chitchat-main{
				&.small-sidebar{
					width: calc(100% - 490px);
					transition: all 0.3s ease;
				}
			}
		}
		li{
			transform: translateX(-100px);
			transition: transform 0.5s ease;
		}
		&.on{
			transition: all 0.3s ease;
			transform: translateX(0px);
			min-width: 100px;
			animation: fadeInLeft 300ms ease-in-out;
			&~.chitchat-main{
				.contact-details{
					width: calc(100vw - 595px);
					transition: all 0.3s ease;
					z-index: 999;
				}
				.message-input{
					width: calc(100% - 500px);
				}
			}
			li{
				transform: translateX(0);
				transition: transform 0.5s ease;
			}
		}
	}
}
.dynemic-sidebar{
	.chat-main{
		display: flex;
		flex-direction: column;
		.pined{
			cursor: pointer;
			order: -1;
		}
	}
}

// settings
.profile-box{
	padding-top: calc(#{$sidebar-spacing} - 10px);
	padding-bottom: $sidebar-spacing;
	.open{
		.details{
			display: none;
			&.edit{
				display: block;
				animation: pulse 300ms ease-in-out;
			}
		}
		.edit-btn{
			&:before{
				content: "\f0c7";
				font-family: FontAwesome;
			}
			svg{
				display: none;
			}
		}
	}
	.details{
		padding-left: 20px;
		padding-top: 13px;
		animation: pulse 300ms ease-in-out;
		&.edit{
			padding-top: 0;
			padding-right: 20px;
			display: none;
		}
	}
	h6{
		padding-top: 6px;
	}
}
.setting-block{
	padding: $sidebar-spacing;
	border-top: 1px solid $border-color;
	margin: 0 -40px;
	h4{
		padding-top: 8px;
	}
	.block{
		position: absolute;
		height: calc(100% - 120px);
		padding: $sidebar-spacing $sidebar-spacing 0 $sidebar-spacing;
		width: 100%;
		top: 115px;
		left: 0;
		bottom: 0;
		background-color: $white;
		opacity: 0;
		z-index: -1;
		border-top: 1px solid $border-color;
		.help{
			padding-top: 20px;
			> li{
				padding-top: 12px;
				padding-bottom: 12px;
				border-top: 1px dashed $border-color;
				a{
					color: rgba($dark-color, 0.8);
					font-weight: 600;
				}
			}
		}
		&.open{
			opacity: 1;
			z-index: 1;
			animation: fadeInLeft 300ms ease-in-out;
		}
	}
}
.setting-block{
	.next,.previous{
		&:hover{
			background-color: $primary-color !important;
			svg{
				stroke: $white !important;
			}
		}
	}
}
.call-log-main{
	.missed{
		stroke: $danger-color !important;
	}
	.call-box{
		position: relative;
		.profile{
			position: absolute;
		}
		.details{
			height: 60px;
			padding-left: 75px;
			padding-right: 100px;
			padding-top: 12px;
			padding-bottom: 13px;
		}
		h6{
			padding-top: 8px;
			display: flex;
			svg{
				height: 13px;
				stroke: $success-color;
				width: 13px;
			}
		}
		.call-status{
			display: inline-block;
			right: 0;
			top: 10px;
			position: absolute;
		}
	}
	li{
		margin-left: -40px;
		margin-right: -40px;
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		cursor: pointer;
		&:before{
			content: "";
			position: absolute;
			height: 100%;
			width: 0px;
			top: 0;
			left: 0;
			background-color: rgba(28, 157, 234, 0.15);
			transition: all 0.3s ease;
		}
		&.active{
			&:before{
				border-left: 4px solid $primary-color;
				width: 100%;
				transition: all 0.3s ease;
			}
		}
	}
}

// profile portfolio //
.portfolio-padding {
	padding-bottom:40px;
}
.portfolio-section {
	.isotopeSelector {
		margin-bottom: 30px;
	}
	.article-title {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		color: white;
		transition: all 0.5s ease;
		text-align:center;
		float:left;
		font-size:18px;
		line-height:24px;
		a {
			color:white;
		}
	}
	.isotopeSelector {
		float:left;
		width:100%;
		position:relative;
		.overlay {
			position: relative ;
			overflow: hidden;
		}
		img {
			width:100%;
			height:auto;
		}
	}
	.isotopeSelector {
		img {
			transition:all .4s ease;
		}
		.overlay-background {
			transform:scale(0);
			transition:all .4s ease;
			border-radius: 15px;
			i {
				position: absolute;
				top: 50%;
				left: 50%;
				background: $primary-color;
				padding: 5px;
				border-radius: 100%;
				transform: translate(-50%, -50%);
				color: $white;
				font-size: 12px;
				display: none;
				transition: all 0.4s ease;
				width: 30px;
				height: 30px;
				align-items: center;
				justify-content: center;
			}
		}
		&:hover {
			img {
				transform:scale(1.2) rotate(2deg);
				transition:all .4s ease;
			}
			.overlay-background {
				transform: scale(1);
				position: absolute;
				left: 0;
				top:0;
				width: 100%;
				height: 100%;
				background-color: rgba( $black , 0.1);
				opacity: 1;
				z-index: 1;
				transition: all .3s linear;
				i {
					display: flex;
					transition: all 0.3s ease;
				}
			}
		}
	}
	.border-portfolio {
		overflow: hidden;
		border-radius: 15px;
	}
	#form1 {
		padding-bottom: 20px;
	}
	.filter-button {
		padding: 15px 24px;
		font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
		border: none;
		background-color: $white;
		margin: 0 5px;
		line-height: 1;
		&.active {
			background-color:$primary-color;
			color: $white;
		}
	}
	&.fullwidth-portfolio {
		.isotopeSelector {
			padding-left: 7px;
			padding-right: 7px;
			margin-bottom: 14px;
		}
	}
}
.filter-section {
	.filter-container {
		text-align:center;
		padding: 0 0 20px;
		ul {
			&.filter {
				> li {
					padding: 0 25px;
					> a {
						color:$primary-color;
						font-size:20px;
						line-height:40px;
						&:hover,
						&:focus {
							text-decoration:none;
							color:$primary-color;
							outline:none;
						}
					}
					&.active {
						background-color:$primary-color;
						a {
							color:$white;
						}
					}
					span {
						color:$border-color2;
						font-size:20px;
						display:inline-block;
						margin:0 5px
					}
				}
			}
		}
		.filter {
			> li {
				padding: 0;
				margin: 0;
			}
		}
	}
	.title1 {
		.title-inner1 {
			margin-top: -5px;
		}
	}
	h1 {
		text-align: center;
	}
}
.demo-colored-wrap {
	background:#ecf0f1;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	transition: all 0.3s ease-out;
}
.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}
.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}
.mfp-title {
	display: none;
}
.offer-box {
	top:50%;
	position: fixed;
	transform: translateY(-50%);
	display: flex;
	left: -415px;
	transition: all 0.5s ease;
	z-index: 9;
	img {
		margin-right: 18px;
	}
	&.toggle-cls {
		left: 0;
		transition: all 0.5s ease;
	}
	.heading-right {
		h3 {
			position: absolute;
			top: 50%;
			right: 0;
			width: 230px;
			height: 36px;
			line-height: 36px;
			letter-spacing: 5px;
			font-size: 18px;
			color: $white;
			font-weight: 700;
			text-transform: uppercase;
			transform: translate(50%, -50%) rotate(-90deg);
			text-align: center;
			background-color: $primary-color;
			margin-bottom: 0;
			cursor: pointer;
		}
	}
}
.integratin{
	margin-top: 20px;
	li{
		position: relative;
		padding-top: 13px;
		padding-bottom: 13px;
		border-bottom: 1px solid $border-color;
		.media-right{
			svg{
				height: 20px;
				cursor: pointer;
				color: $gray;
			}
		}
		.media-left{
			width: 100%;
			a{
				font-size: 16px;
				h5{
					display: inline-block;
					font-size: 16px;
				}
			}
		}
		.fb{
			color: $facebook;
			h5{
				color: $facebook;
			}
		}
		.insta{
			color: $instagram;
			h5{
				color: $instagram;
			}
		}
		.twi{
			color: $twiter;
			h5{
				color: $twiter;
			}
		}
		.slc{
			color: $slack;
			h5{
				color: $slack;
			}
		}
		.ggl{
			color: $google;
			h5{
				color: $google;
			}
		}
		p{
			padding-left: 20px;
			padding-top: 7px;
		}
		.profile{
			height: 20px;
			width: 20px;
			margin-top: 3px;
		}
	}
}
.switch-list{
	margin-top: 10px;
	li{
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		position: relative;
		padding-left: 50px;
		border: 0 !important;
		p{
			margin-bottom: 0;
		}
	}
	.switchery{
		position: absolute;
		left: 0;
		top: 8px;
	}
	h5{
		color: $muted-font;
		font-weight: 600;
	}
}
.wallpaper{
	margin-top: 15px;
	li{
		cursor: pointer;
		height: 30px;
		width: 30px;
		background-color: $primary-light;
		display: inline-block !important;
		background-size: auto !important;
	}
}
.change-number{
	h5{
		padding-bottom: 10px;
		font-weight: 600;
		color: $dark-color;
	}
	.input-group{
		&~h5{
			padding-top: 10px;
		}
	}
	span{
		border-right: none;
		padding-right: 0;
		font-weight: 500;
	}
	.country-code{
		max-width: 35px;
		padding: 0;
		border-left: none;
		padding-left: 3px;
	}
	.form-control{
		width: 100%;
		padding-top: 7px;
		padding-bottom: 7px;
		border-color: $gray1;
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
		&[type=number] {
			-moz-appearance:textfield;
		}
	}
	.btn-sm{
		padding: 8px 20px;
		font-size: 14px;
		margin-top: 20px;
	}
}
.privacy{
	li{
		position: relative;
	}
	h5{
		padding-top: 10px;
		padding-right: 35px;
		color: $dark-color;
		line-height: 1.6;
	}
	p{
		margin-bottom: 10px;
	}
	.switchery-small{
		position: absolute;
		right: 0;
		top: 8px;
	}
}

.app-list-ul{
	.title{
		border-bottom: 1px solid $border-color;
		padding-bottom: 15px;
		cursor: pointer;
		svg{
			stroke: $primary-color;
		}
		h5{
			margin-top: 0 !important;
		}
	}
	text-align: center;
	li {
		margin-bottom: 20px;
	}
}

.lightbox-target {
	position: fixed;
	top: -100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.7);
	opacity: 0;
	transition: opacity .5s ease-in-out;
	overflow: hidden;
	left : 0;
	img {
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		max-height: 0%;
		max-width: 0%;
		border: 3px solid white;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
		box-sizing: border-box;
		transition: .5s ease-in-out;
	}
}

a.lightbox-close {
	display: block;
	width: 50px;
	height: 50px;
	box-sizing: border-box;
	background: white;
	color: black;
	text-decoration: none;
	position: absolute;
	top: -80px;
	transition: .5s ease-in-out;
	&:before {
		content: "";
		display: block;
		height: 30px;
		width: 1px;
		background: black;
		position: absolute;
		left: 26px;
		top: 10px;
		transform: rotate(45deg);
	}
	&:after {
		content: "";
		display: block;
		height: 30px;
		width: 1px;
		background: black;
		position: absolute;
		left: 26px;
		top: 10px;
		transform: rotate(-45deg);
	}
}
.lightbox-target:target {
	opacity: 1;
	top: 0;
	bottom: 0;
	img {
		max-height: 100%;
		max-width: 100%;
	}
	a.lightbox-close {
		top: 0;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, 70px);
	}
}
.lightbox-target img{
	border: none;
	border-radius: 25px;
	overflow: hidden;
}
.chat-content{
	.message-input{
		.emojis-main{
			.typing-section{
				background-color: $white;
				border: 1px solid $white;
				border-radius: 21px;
				padding: 0 15px;
				margin: 5px 10px;
				box-sizing: border-box;
				flex: 1 1 auto;
				.typ-msg-content{
					position: relative;
					flex: 1;
					display: flex;
					overflow: hidden;
					padding-right: 0;
					.typ-msg{
						left: 10px;
						top: 5px;
						color: #999;
						font-size: 15px;
						line-height: 20px;
						pointer-events: none;
						position: absolute;
						transition: opacity .08s linear;
						user-select: none;
						z-index: 2;
					}
					.typ-msg-main{
						font-size: 15px;
						line-height: 30px;
						height: 35px;
						width: 100%;
						user-select: text;
					}
				}
			}
		}
	}
}
.mobile-sidebar,.mobile-back{
	display: none;
}


// chat-cont setting //

ul.chat-cont-setting {
	background-color: $white;
	box-shadow: 0 0 10px $gray1;
	padding:15px;
	width: fit-content ;
	text-align: right; 
	position: fixed;
	left: 20% ;
	bottom: 10%;
	z-index: 1;
	display: none;
	transition:all 0.5s ease ;
	&.open {
		display: block;
	}
	li {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0 ;
		}
		a {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			span {
				padding: 5px;
				line-height: 1;
				text-transform: capitalize;
				margin-right: 15px;
				font-size: 12px;
			}
			.icon-chat-cont{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 10px;
				svg {
					width: 20px;
					height: 20px;
					fill:$white;
				}
			}
		}

	}
}
.msg-chat-modal  {
	.chat-msg-search {
		margin-bottom: 20px;
		.input-group {
			align-items: center;
			background-color: $border-color ;
			border:1px solid $border-color ;
			.form-control {
				border:none;
			}
			.input-group-append {
				padding: 0 15px;
			}
		}
	}

	.chat-main,.call-log-main{
		height: 350px;
		overflow: auto;
		// border-top:2px solid $primary-color ;
		li {
			margin-left: 0;
			margin-right: 0;
			.chat-box  {
				.details {
					padding-left: 70px;
				}
			}			
		}
	}
}

.snippet-modal-main {
	.default-form {
		.form-group{
			margin-bottom: 25px;
			input,select {
				font-size: 14px;
				text-transform: capitalize;
				&::placeholder {
					text-transform:capitalize;
					font-size: 14px;
				}
			}

			.btn-snipate {
				margin-top: 40px;
			}
		}
	}
	
}	